import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import SectionHeading from '../components/sectionHeading'
import { StaticImage } from 'gatsby-plugin-image'
import "../styles/index.scss"
import { SEO } from "../components/seo"

const FloorPlans = props => {
  return (
    <Layout pageTitle="Floor Plans">
        <div className="floorPlanContainer">
            <div className="floorPlanItem">
                <StaticImage
                    className="floorPlanImage"
                    alt="Floor plan example"
                    src="../media/images/FloorPlan1.jpg"
                />
            </div>
            <div className="floorPlanItem">
                <StaticImage
                    className="floorPlanImage"
                    alt="Floor plan example"
                    src="../media/images/FloorPlan2.jpg"
                />
            </div>
        
        </div>       
    </Layout>
  )
}

export const Head = () => (
    <SEO title="Floor plans" />
)

export default FloorPlans;