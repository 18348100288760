import React, { useState, useEffect } from 'react'
import * as sectionCSS from './sectionHeading.module.css'

const SectionHeading = ({ sectionHeadingTitle, sectionHeadingText, sectionAnchor}) => {

  const [inView, setInView] = useState("notInView");
  const [distanceToTopScreen, setDistanceToTopScreen] = useState(null);

  useEffect(() => {
    const onScroll = () => {
      if(sectionAnchor === 'listingPackagesAnchor'){
      var el = document.getElementById(sectionAnchor) ?? null;
      if(el){
        var distance = el.getBoundingClientRect().top;
      }else{
        var distance = 0;        
      }
      setDistanceToTopScreen(distance);
      //if = 0 top of element at top of screen
      //if > 0 and < vh in screen
      //if = vh top of element at bottom of screen


      
      if(distanceToTopScreen > 100 && distanceToTopScreen < 600){
        setInView('inView');
      }else{
        setInView('notInView1');
      }
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
   
    return () => window.removeEventListener('scroll', onScroll);
}, []);

  return (
    <div 
      className="sectionHeadingDiv"
      data-sal="slide-right"
      data-sal-delay="1200"
      data-sal-easing="ease"
      data-sal-duration="1200"
      >
        <div id={sectionAnchor}>
            <div className="sectionHeadingTextStyle">
                {sectionHeadingText}
            </div>
            <h3 className="sectionHeadingTitleStyle">
                {sectionHeadingTitle}{/* {inView} */}{/* {distanceToTopScreen} */}
            </h3>
        </div>

    </div>
  )
}

export default SectionHeading